// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./App.scss";
import Description from "./components/Description";
import Gallery from "./components/Gallery";
import Header from "./components/Header";
import Main2 from "./components/Main2";

function App() {
  return (
    <>
      <Header />

      <Description />
      <Gallery />

      <Main2 />
      {/* <Footer /> */}
    </>
  );
}

export default App;
