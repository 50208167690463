/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import "./Main.scss";
import { AiOutlineGlobal } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import myLogo from "../files/my_logo.svg";

function Main2() {
  return (
    <main>
      <div className="infoPhoneMail">
        <p>+48 503 938 063</p>
        <a href="https://wa.me/48503938063">
          <BsWhatsapp fontSize="50px" />
        </a>
      </div>

      <div className="blockMain">
        <div className="iconSVG">
          <FiMail className="footer-social-icon" fontSize="2.5em" />
        </div>
        <div className="infoBlock">info@ariana3dmodeling.pl</div>
      </div>

      <div className="blockMain">
        <div className="iconSVG">
          <AiOutlineGlobal className="footer-social-icon" fontSize="2.5em" />
        </div>
        <div className="infoBlock">www.ariana3dmodeling.pl</div>
      </div>

      {/* <div className="myLogo">
        created by
        <div className="myLogo_img">
          <img src={myLogo} alt="myLogo" />
        </div>
      </div> */}
      {/* <div className="footer_block">
        <p>created by</p>
       
        <a
          href="https://asatryanhov.github.io/AsatryanHov/"
          className="footer-copyright-name"
          target="_blank"
          rel="noreferrer">
          <img src={myLogo} alt="logo" />
        </a>
      </div> */}
      <a
        className="footer_block"
        href="https://asatryanhov.pl/"
        target="_blank">
        <p>created by</p>
        {/* <p>Asatryan Hov</p> */}
        <div className="footer-copyright-name" target="_blank" rel="noreferrer">
          <img src={myLogo} alt="logo" />
        </div>
      </a>
    </main>
  );
}

export default Main2;
