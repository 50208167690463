// Slider.js
import React, { useState } from "react";
import Swipe from "react-easy-swipe";
import "./Slider.scss"; // optional, for slider styling

// import img1_2 from "../image/emerald/3/image (2).jpg";
// import img1_4 from "../image/emerald/3/image (4).jpg";
// import img1_5 from "../image/emerald/3/image (5).jpg";

const Slider = ({ dataArr }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // const slides = [img1_2, img1_4, img1_5];
  const slides = dataArr;

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  const onSwipeLeft = () => {
    goToNext();
  };

  const onSwipeRight = () => {
    goToPrev();
  };

  return (
    <div className="slider-container">
      <button className="slider-btn prev-btn" onClick={goToPrev}>
        ◀
      </button>

      <Swipe
        onSwipeLeft={onSwipeLeft}
        onSwipeRight={onSwipeRight}
        allowMouseEvents={true}>
        <div className="slide">
          <img src={slides[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        </div>
      </Swipe>

      <button className="slider-btn next-btn" onClick={goToNext}>
        ▶
      </button>

      <div className="dots">
        {slides.map((_, idx) => (
          <span
            key={idx}
            className={`dot ${idx === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(idx)}></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
