// import Slider from "./slider/Slider";
// import { FaTimes } from "react-icons/fa"; // Importing a nice close icon
// import { GrGallery } from "react-icons/gr";
// import React, { useState, useRef } from "react";

// const ImageBlock = ({ imgSrc, imgArr, altText, handleImageClick }) => {
//   const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
//   const modalRef = useRef(null);

//   // Function to toggle modal visibility
//   const toggleModalGallery = (e) => {
//     e.stopPropagation(); // Prevent modal from closing when clicking inside content
//     setIsGalleryModalOpen(!isGalleryModalOpen); // Toggle based on isGalleryModalOpen
//   };

//   const handleModalClick = (e) => {
//     if (modalRef.current && !modalRef.current.contains(e.target)) {
//       toggleModalGallery(e);
//     }
//   };

//   // Check if imgSrc or imgArr is empty, return null if true
//   if (!imgSrc || !imgArr || imgArr.length === 0) {
//     return null;
//   }

//   return (
//     <div className="wrapIMG">
//       <img
//         src={imgSrc}
//         alt={altText}
//         className="gallery-image"
//         onClick={() => handleImageClick(imgSrc)}
//       />

//       <div className="card-btns-wrapper">
//         <button
//           className="btn-show-galley"
//           onClick={(e) => {
//             e.stopPropagation();
//             toggleModalGallery(e);
//           }}>
//           <GrGallery />
//         </button>
//       </div>

//       {isGalleryModalOpen && (
//         <div className="modal-overlay" onClick={handleModalClick}>
//           <div className="modal-content" ref={modalRef}>
//             <button className="close-btn" onClick={toggleModalGallery}>
//               <FaTimes /> {/* Close icon */}
//             </button>
//             <div className="pics">
//               <Slider dataArr={imgArr} />
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageBlock;

import Slider from "./slider/Slider";
import { FaTimes } from "react-icons/fa"; // Importing a close icon
// import { GrGallery } from "react-icons/gr";
import React, { useState, useRef } from "react";

const ImageBlock = ({
  imgSrc = "",
  imgArr = [],
  altText = "Gallery Image",
  handleImageClick = () => {},
}) => {
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const modalRef = useRef(null);

  // Function to toggle modal visibility
  const toggleModalGallery = (e) => {
    e.stopPropagation();
    setIsGalleryModalOpen(!isGalleryModalOpen);
  };

  // Close modal if click is outside the content area
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsGalleryModalOpen(false);
    }
  };

  // Check if imgSrc or imgArr is empty
  if (!imgSrc || imgArr.length === 0) {
    return null;
  }

  return (
    <div className="wrapIMG">
      {/* Main image click now directly opens the modal with slider */}
      <img
        src={imgSrc}
        alt={altText}
        className="gallery-image"
        onClick={toggleModalGallery} // Directly open modal on image click
      />

      {/* <div className="card-btns-wrapper">
        
        <button
          className="btn-show-gallery"
          onClick={(e) => {
            e.stopPropagation();
            toggleModalGallery(e);
          }}>
          <GrGallery />
        </button>
      </div> */}

      {/* Modal for the image gallery */}
      {isGalleryModalOpen && (
        <div className="modal-overlay" onClick={handleOutsideClick}>
          <div className="modal-content" ref={modalRef}>
            <button className="close-btn" onClick={toggleModalGallery}>
              <FaTimes />
            </button>
            <div className="pics">
              <Slider dataArr={imgArr} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageBlock;
