import ImageBlock from "./ImageBlock.js";
import "./GalleryItem.scss";

import React, { useState } from "react";
import Modal from "./Modal.js";

function GalleryItem({
  title,
  src1,
  src1Arr,
  src2,
  src2Arr,
  src3,
  src3Arr,
  src4,
  src4Arr,
  src5,
  src5Arr,
  src6,
  src6Arr,
  src7,
  src7Arr,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (src) => {
    setSelectedImage(src);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <div className="gallery-title">{title}</div>
      <div className="gallery-images-block">
        <ImageBlock
          imgSrc={src1}
          imgArr={src1Arr}
          altText="Image 1"
          handleImageClick={handleImageClick}
        />
        <ImageBlock
          imgSrc={src2}
          imgArr={src2Arr}
          altText="Image 2"
          handleImageClick={handleImageClick}
        />
        <ImageBlock
          imgSrc={src3}
          imgArr={src3Arr}
          altText="Image 3"
          handleImageClick={handleImageClick}
        />
        <ImageBlock
          imgSrc={src4}
          imgArr={src4Arr}
          altText="Image 4"
          handleImageClick={handleImageClick}
        />
        <ImageBlock
          imgSrc={src5}
          imgArr={src5Arr}
          altText="Image 5"
          handleImageClick={handleImageClick}
        />
        <ImageBlock
          imgSrc={src6}
          imgArr={src6Arr}
          altText="Image 6"
          handleImageClick={handleImageClick}
        />
        <ImageBlock
          imgSrc={src7}
          imgArr={src7Arr}
          altText="Image 7"
          handleImageClick={handleImageClick}
        />
      </div>

      <Modal isOpen={isOpen} src={selectedImage} onClose={closeModal} />
    </>
  );
}

export default GalleryItem;
