import React from "react";
import "./Header.scss"; // Assuming you have a CSS file for styling
import logo from "../files/logo.png";
import poster from "../files/bg_ring.jpg";
import videoSRC from ".././files/bg_video_ariana.mp4";

// import { AiOutlineGlobal } from "react-icons/ai";
const Header = () => {
  document.addEventListener("DOMContentLoaded", function () {
    // Get the video element
    let video = document.getElementById("myVideo");
    video.play();

    document.addEventListener("scroll", function () {
      // Play the video
      video.play();
      console.log("Scrolled");
    });

    document.addEventListener("click", function () {
      // Play the video
      video.play();
      console.log("Clicked");
    });

    document.addEventListener("touchstart", function (event) {
      event.preventDefault();
      video.play();
      console.log("Touched");
    });
  });
  return (
    <header className="header" id="header">
      <div className="header_blocks block_logo_title">
        <img src={logo} alt="logo" className="header_logo" />
        <h1 className="header-title">ARIANA MODELING STUDIO</h1>
        <div className="header-descr header-title">
          <span>3D MODELING</span>
          <span>JEWELRY CAD DESIGN</span>
          <span>REALISTIC RENDERING</span>
        </div>
      </div>

      {/* <div className="header_blocks">
        <a href="#portfolio" className="header-arrow">
          <img src="./files/select-arrow.svg" alt="Arrow" />
        </a>
      </div> */}

      {/* <div className="subtitle">Website is under construction</div> */}

      <video
        id="myVideo"
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
        poster={poster}>
        {/* <source src="./files/cover.webm" type="video/webm" /> */}
        <source src={videoSRC} type="video/mp4" />
      </video>
    </header>
  );
};

export default Header;
