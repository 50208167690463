import "./Description.scss";
import img_descr from "../files/descr_img.jpg";

function Description() {
  return (
    <div className="description_block">
      <div className="descr_img">
        <img src={img_descr} alt="descr_image" />
      </div>
      <div className="descr_text">
        <div>
          {" "}
          Our Jewelry Cad team provides customized cad modelling services for
          independent jewelry designers, retailers & manufacturers. Based upon
          the data provided by the customer, our Cad team will produce a 3D
          model using CAD Modelling softwares. The model will be rendered to
          give a realistic feel that can be further used for marketing and
          communication purposes.{" "}
        </div>
        <div>
          Our Cad modelling services are unique in a way that we focus on the
          customer requirement rather than offering something ready made.
          Customization is the key to products today. In an era where customers
          are buying even shoes which are customized to their requirement,
          jewelry is much more personalized and luxurious to be added to a
          shopping cart online. Hence, it requires uniqueness that can be only
          offered through customization.{" "}
        </div>
        <div>
          We can design simple to complex models like engagement rings,
          pendants, cocktail rings, graduation rings, earrings, textured
          patterns, abstract designs, human and animal figures.
        </div>
        <div>
          Our endeavour is to give our customer the best possible solution for
          3D Jewelry modelling in the fastest turnaround time.
        </div>
        <div>
          We have successfully designed 10,000+ cad models for jewelry
          manufacturers and retailers across US, Canada, Israel and Europe.
        </div>
      </div>
    </div>
  );
}

export default Description;
