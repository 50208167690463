// Modal.jsx
import React from "react";
import "./Modal.scss";

function Modal({ isOpen, src, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <img src={src} alt="Large view" className="modal-image" />
      </div>
    </div>
  );
}

export default Modal;
