/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import "./Gallery.scss";
import GalleryItem from "./GalleryItem";

//////////////////////  round start  ////////////////////////////
// 1
import img1_1r from "./image/round/1/image (1).jpg";
import img1_2r from "./image/round/1/image (2).jpg";
import img1_3r from "./image/round/1/image (3).jpg";
import img1_4r from "./image/round/1/image (4).jpg";
import img1_5r from "./image/round/1/image (5).jpg";

// 2
import img2_1r from "./image/round/2/image (1).jpg";
import img2_2r from "./image/round/2/image (2).jpg";
import img2_3r from "./image/round/2/image (3).jpg";
import img2_4r from "./image/round/2/image (4).jpg";
import img2_5r from "./image/round/2/image (5).jpg";

// 3
import img3_1r from "./image/round/3/image (1).jpg";
import img3_2r from "./image/round/3/image (2).jpg";
import img3_3r from "./image/round/3/image (3).jpg";
import img3_4r from "./image/round/3/image (4).jpg";
import img3_5r from "./image/round/3/image (5).jpg";

// 4
import img4_1r from "./image/round/4/image (1).jpg";
import img4_2r from "./image/round/4/image (2).jpg";
import img4_3r from "./image/round/4/image (3).jpg";
import img4_4r from "./image/round/4/image (4).jpg";
import img4_5r from "./image/round/4/image (5).jpg";

// 5
import img5_1r from "./image/round/5/image (1).jpg";
import img5_2r from "./image/round/5/image (2).jpg";
import img5_3r from "./image/round/5/image (3).jpg";
import img5_4r from "./image/round/5/image (4).jpg";
import img5_5r from "./image/round/5/image (5).jpg";

// 6
import img6_1r from "./image/round/6/image (1).jpg";
import img6_2r from "./image/round/6/image (2).jpg";
import img6_3r from "./image/round/6/image (3).jpg";
import img6_4r from "./image/round/6/image (4).jpg";
import img6_5r from "./image/round/6/image (5).jpg";

// 7
import img7_1r from "./image/round/7/image (1).jpg";
import img7_2r from "./image/round/7/image (2).jpg";
import img7_3r from "./image/round/7/image (3).jpg";
import img7_4r from "./image/round/7/image (4).jpg";
import img7_5r from "./image/round/7/image (5).jpg";
//////////////////////  round end  ////////////////////////////

//////////////////////  emerald start  ////////////////////////////
// 1
import img1_1e from "./image/emerald/1/image (1).jpg";
import img1_2e from "./image/emerald/1/image (2).jpg";
import img1_3e from "./image/emerald/1/image (3).jpg";
import img1_4e from "./image/emerald/1/image (4).jpg";
import img1_5e from "./image/emerald/1/image (5).jpg";

// 2
import img2_1e from "./image/emerald/2/image (1).jpg";
import img2_2e from "./image/emerald/2/image (2).jpg";
import img2_3e from "./image/emerald/2/image (3).jpg";
import img2_4e from "./image/emerald/2/image (4).jpg";
import img2_5e from "./image/emerald/2/image (5).jpg";

// 3
import img3_1e from "./image/emerald/3/image (1).jpg";
import img3_2e from "./image/emerald/3/image (2).jpg";
import img3_3e from "./image/emerald/3/image (3).jpg";
import img3_4e from "./image/emerald/3/image (4).jpg";
import img3_5e from "./image/emerald/3/image (5).jpg";

// 4
import img4_1e from "./image/emerald/4/image (1).jpg";
import img4_2e from "./image/emerald/4/image (2).jpg";
import img4_3e from "./image/emerald/4/image (3).jpg";
import img4_4e from "./image/emerald/4/image (4).jpg";
import img4_5e from "./image/emerald/4/image (5).jpg";

// 5
import img5_1e from "./image/emerald/5/image (1).jpg";
import img5_2e from "./image/emerald/5/image (2).jpg";
import img5_3e from "./image/emerald/5/image (3).jpg";
import img5_4e from "./image/emerald/5/image (4).jpg";
import img5_5e from "./image/emerald/5/image (5).jpg";

// 6
import img6_1e from "./image/emerald/6/image (1).jpg";
import img6_2e from "./image/emerald/6/image (2).jpg";
import img6_3e from "./image/emerald/6/image (3).jpg";
import img6_4e from "./image/emerald/6/image (4).jpg";
import img6_5e from "./image/emerald/6/image (5).jpg";

// 7
import img7_1e from "./image/emerald/7/image (1).jpg";
import img7_2e from "./image/emerald/7/image (2).jpg";
import img7_3e from "./image/emerald/7/image (3).jpg";
import img7_4e from "./image/emerald/7/image (4).jpg";
import img7_5e from "./image/emerald/7/image (5).jpg";
//////////////////////  emerald end  ////////////////////////////

//////////////////////  oval start  ////////////////////////////
// 1
import img1_1o from "./image/oval/1/image (1).jpg";
import img1_2o from "./image/oval/1/image (2).jpg";
import img1_3o from "./image/oval/1/image (3).jpg";
import img1_4o from "./image/oval/1/image (4).jpg";
import img1_5o from "./image/oval/1/image (5).jpg";

// 2
import img2_1o from "./image/oval/2/image (1).jpg";
import img2_2o from "./image/oval/2/image (2).jpg";
import img2_3o from "./image/oval/2/image (3).jpg";
import img2_4o from "./image/oval/2/image (4).jpg";
import img2_5o from "./image/oval/2/image (5).jpg";

// 3
import img3_1o from "./image/oval/3/image (1).jpg";
import img3_2o from "./image/oval/3/image (2).jpg";
import img3_3o from "./image/oval/3/image (3).jpg";
import img3_4o from "./image/oval/3/image (4).jpg";
import img3_5o from "./image/oval/3/image (5).jpg";

// 4
import img4_1o from "./image/oval/4/image (1).jpg";
import img4_2o from "./image/oval/4/image (2).jpg";
import img4_3o from "./image/oval/4/image (3).jpg";
import img4_4o from "./image/oval/4/image (4).jpg";
import img4_5o from "./image/oval/4/image (5).jpg";

// 5
import img5_1o from "./image/oval/5/image (1).jpg";
import img5_2o from "./image/oval/5/image (2).jpg";
import img5_3o from "./image/oval/5/image (3).jpg";
import img5_4o from "./image/oval/5/image (4).jpg";
import img5_5o from "./image/oval/5/image (5).jpg";

// 6
import img6_1o from "./image/oval/6/image (1).jpg";
import img6_2o from "./image/oval/6/image (2).jpg";
import img6_3o from "./image/oval/6/image (3).jpg";
import img6_4o from "./image/oval/6/image (4).jpg";
import img6_5o from "./image/oval/6/image (5).jpg";

// 7
import img7_1o from "./image/oval/7/image (1).jpg";
import img7_2o from "./image/oval/7/image (2).jpg";
import img7_3o from "./image/oval/7/image (3).jpg";
import img7_4o from "./image/oval/7/image (4).jpg";
import img7_5o from "./image/oval/7/image (5).jpg";
//////////////////////  oval end  ////////////////////////////

//////////////////////  pear start  ////////////////////////////
// 1
// import img1_1p from "./image/pear/1/image (1).jpg";
// import img1_2p from "./image/pear/1/image (2).jpg";
// import img1_3p from "./image/pear/1/image (3).jpg";
// import img1_4p from "./image/pear/1/image (4).jpg";
// import img1_5p from "./image/pear/1/image (5).jpg";

// 2
import img2_1p from "./image/pear/2/image (1).jpg";
import img2_2p from "./image/pear/2/image (2).jpg";
import img2_3p from "./image/pear/2/image (3).jpg";
import img2_4p from "./image/pear/2/image (4).jpg";
import img2_5p from "./image/pear/2/image (5).jpg";

// 3
import img3_1p from "./image/pear/3/image (1).jpg";
import img3_2p from "./image/pear/3/image (2).jpg";
import img3_3p from "./image/pear/3/image (3).jpg";
import img3_4p from "./image/pear/3/image (4).jpg";
import img3_5p from "./image/pear/3/image (5).jpg";

// 4
import img4_1p from "./image/pear/4/image (1).jpg";
import img4_2p from "./image/pear/4/image (2).jpg";
import img4_3p from "./image/pear/4/image (3).jpg";
import img4_4p from "./image/pear/4/image (4).jpg";
import img4_5p from "./image/pear/4/image (5).jpg";

// 5
import img5_1p from "./image/pear/5/image (1).jpg";
import img5_2p from "./image/pear/5/image (2).jpg";
import img5_3p from "./image/pear/5/image (3).jpg";
import img5_4p from "./image/pear/5/image (4).jpg";
import img5_5p from "./image/pear/5/image (5).jpg";

// 6
// import img6_1p from "./image/pear/6/image (1).jpg";
// import img6_2p from "./image/pear/6/image (2).jpg";
// import img6_3p from "./image/pear/6/image (3).jpg";
// import img6_4p from "./image/pear/6/image (4).jpg";
// import img6_5p from "./image/pear/6/image (5).jpg";

// 7
import img7_1p from "./image/pear/7/image (1).jpg";
import img7_2p from "./image/pear/7/image (2).jpg";
import img7_3p from "./image/pear/7/image (3).jpg";
import img7_4p from "./image/pear/7/image (4).jpg";
import img7_5p from "./image/pear/7/image (5).jpg";
//////////////////////  pear end  ////////////////////////////

//////////////////////  cushion start  ////////////////////////////
// 1
import img1_1c from "./image/cushion/1/image (1).jpg";
import img1_2c from "./image/cushion/1/image (2).jpg";
import img1_3c from "./image/cushion/1/image (3).jpg";
import img1_4c from "./image/cushion/1/image (4).jpg";
import img1_5c from "./image/cushion/1/image (5).jpg";

//////////////////////  cushion end  ////////////////////////////

function Gallery() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDiv = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <h2
        className="gallery-button"
        onClick={toggleDiv}
        style={{ cursor: "pointer" }}>
        Gallery
      </h2>

      <div className={`toggle-content ${isOpen ? "open" : "closed"}`}>
        <GalleryItem
          title="Round"
          src1={img1_1r}
          src1Arr={[img1_2r, img1_1r, img1_3r, img1_4r, img1_5r]}
          src2={img2_1r}
          src2Arr={[img2_2r, img2_1r, img2_3r, img2_4r, img2_5r]}
          src3={img3_1r}
          src3Arr={[img3_2r, img3_1r, img3_3r, img3_4r, img3_5r]}
          src4={img4_1r}
          src4Arr={[img4_2r, img4_1r, img4_3r, img4_4r, img4_5r]}
          src5={img5_1r}
          src5Arr={[img5_2r, img5_1r, img5_3r, img5_4r, img5_5r]}
          src6={img6_1r}
          src6Arr={[img6_2r, img6_1r, img6_3r, img6_4r, img6_5r]}
          src7={img7_1r}
          src7Arr={[img7_2r, img7_1r, img7_3r, img7_4r, img7_5r]}
        />

        <GalleryItem
          title="Emerald"
          src1={img1_1e}
          src1Arr={[img1_2e, img1_1e, img1_3e, img1_4e, img1_5e]}
          src2={img2_1e}
          src2Arr={[img2_2e, img2_1e, img2_3e, img2_4e, img2_5e]}
          src3={img3_1e}
          src3Arr={[img3_2e, img3_1e, img3_3e, img3_4e, img3_5e]}
          src4={img4_1e}
          src4Arr={[img4_2e, img4_1e, img4_3e, img4_4e, img4_5e]}
          src5={img5_1e}
          src5Arr={[img5_2e, img5_1e, img5_3e, img5_4e, img5_5e]}
          src6={img6_1e}
          src6Arr={[img6_2e, img6_1e, img6_3e, img6_4e, img6_5e]}
          src7={img7_1e}
          src7Arr={[img7_2e, img7_1e, img7_3e, img7_4e, img7_5e]}
        />

        <GalleryItem
          title="Oval"
          src1={img1_1o}
          src1Arr={[img1_2o, img1_1o, img1_3o, img1_4o, img1_5o]}
          src2={img2_1o}
          src2Arr={[img2_2o, img2_1o, img2_3o, img2_4o, img2_5o]}
          src3={img3_1o}
          src3Arr={[img3_2o, img3_1o, img3_3o, img3_4o, img3_5o]}
          src4={img4_1o}
          src4Arr={[img4_2o, img4_1o, img4_3o, img4_4o, img4_5o]}
          src5={img5_1o}
          src5Arr={[img5_2o, img5_1o, img5_3o, img5_4o, img5_5o]}
          src6={img6_1o}
          src6Arr={[img6_2o, img6_1o, img6_3o, img6_4o, img6_5o]}
          src7={img7_1o}
          src7Arr={[img7_2o, img7_1o, img7_3o, img7_4o, img7_5o]}
        />

        <GalleryItem
          title="Pear"
          // src1={img1_1p}
          // src1Arr={[img1_2p, img1_1p, img1_3p, img1_4p, img1_5p]}
          src2={img2_1p}
          src2Arr={[img2_2p, img2_1p, img2_3p, img2_4p, img2_5p]}
          src3={img3_1p}
          src3Arr={[img3_2p, img3_1p, img3_3p, img3_4p, img3_5p]}
          src4={img4_1p}
          src4Arr={[img4_2p, img4_1p, img4_3p, img4_4p, img4_5p]}
          src5={img5_1p}
          src5Arr={[img5_2p, img5_1p, img5_3p, img5_4p, img5_5p]}
          // src6={img6_1p}
          // src6Arr={[img6_2p, img6_1p, img6_3p, img6_4p, img6_5p]}
          src7={img7_1p}
          src7Arr={[img7_2p, img7_1p, img7_3p, img7_4p, img7_5p]}
        />

        <GalleryItem
          title="Cushion"
          src1={img1_1c}
          src1Arr={[img1_2c, img1_1c, img1_3c, img1_4c, img1_5c]}
          // src2={img2_1p}
          // src2Arr={[img2_2p, img2_1p, img2_3p, img2_4p, img2_5p]}
          // src3={img3_1p}
          // src3Arr={[img3_2p, img3_1p, img3_3p, img3_4p, img3_5p]}
          // src4={img4_1p}
          // src4Arr={[img4_2p, img4_1p, img4_3p, img4_4p, img4_5p]}
          // src5={img5_1p}
          // src5Arr={[img5_2p, img5_1p, img5_3p, img5_4p, img5_5p]}
          // // src6={img6_1p}
          // src6Arr={[img6_2p, img6_1p, img6_3p, img6_4p, img6_5p]}
          // src7={img7_1p}
          // src7Arr={[img7_2p, img7_1p, img7_3p, img7_4p, img7_5p]}
        />
      </div>
    </>
  );
}

export default Gallery;
